import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby-plugin-react-intl'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

//Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import Title from '../components/title'

// Styles
import { Container, Row, Col } from 'react-bootstrap'
import * as styles from '../styles/templates/doctor.module.scss'

export const query = graphql`
  query ($slug: String!) {
    contentfulDoctor(slug: { eq: $slug }) {
      slug
      name
      specialization
      description {
        description
      }
      location {
        slug
        location
        address {
          address
        }
      }
      image {
        gatsbyImageData(
          quality: 100
          width: 200
          height: 200
          layout: CONSTRAINED
        )
      }
    }
  }
`

export default function Doctor ({ data }) {
  const doctor = data.contentfulDoctor
  const desc = doctor.description.description.split('\n')
  return (
    <Layout>
      <SEO
        title={doctor.name}
        description={doctor.description.description}
        article={false}
        pathname={`/providers/${doctor.slug}`}
      />

      <Title title={doctor.name} />

      <Container>
        <Col lg={8} className='mx-auto'>
          <Row>
            <Col className='text-center my-5 mx-auto'>
              {doctor.image ? (
                <GatsbyImage
                  image={doctor.image.gatsbyImageData}
                  className={`${styles.image} mb-4 mb-xl-0 `}
                  alt={doctor.name}
                />
              ) : (
                <StaticImage
                  src='../images/icon.png'
                  alt='logo'
                  objectPosition='center'
                  width={350}
                />
              )}
            </Col>
          </Row>
          <Row className={`align-items-center gx-5`}>
            <Col>
              <h2 className={`${styles.name}`}>{doctor.name}</h2>

              <hr className='my-4 mb-4' />
            </Col>
          </Row>

          <Row>
            <div className={`my-5 ${styles.desc}`}>
              {desc.map(item => (
                <p>{item}</p>
              ))}
            </div>

            <div className='mb-5'>
              <p className='h3 mb-4'>Text Us</p>
              <p>
                English <a href='sms:8186582701'>818-658-2701</a>
                <br /> Spanish <a href='sms:8184235637'>818-423-5637</a>
              </p>
            </div>
            <div className='mb-5'>
              <p className='h3 mb-4'>Locations</p>
              {doctor.location?.map((location, idx) => {
                return (
                  <div key={idx} className='my-5'>
                    <p>
                      <Link to={`/locations/${location.slug}`}>
                        {location.location}
                      </Link>
                    </p>

                    <p>{location.address.address} </p>
                  </div>
                )
              })}
            </div>
          </Row>
        </Col>
      </Container>
    </Layout>
  )
}
